import "../client/styles/globals.css"
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-day-picker/dist/style.css';

// import 'react-day-picker/style.css'
import { useEffect, useState } from 'react';
import { GeistProvider, CssBaseline } from '@geist-ui/core'
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { Analytics } from '@vercel/analytics/react';

function MyApp({ Component, pageProps }) {
  const [modal, setModal] = useState()
  const close = () => setModal()

  useEffect(() => { defineCustomElements(window) });

  return <GeistProvider>
    <CssBaseline />
    <Component modal={modal} setModal={setModal} close={close}   {...pageProps} />
    <Analytics/>
  </GeistProvider >
}


export default MyApp
